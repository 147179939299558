// MAIN CSS 
html,
body {
  margin: 0;
  padding: 0;
  background-color: #f1f2f6 !important;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;
}

.sticky-div {
  z-index: 998;
  position: sticky;
  top: 60px;
  background: #f5f5f9;
  border-radius: 3px;
  margin-bottom: 9px;
  padding-left: 10px;
}

.swal2-styled {
  width: 100px;
}

.form-style {
  margin-left: 250px;
}
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: url(./assets/background.jpg) no-repeat center fixed;
  -webkit-background-size: cover;
  /* pour anciens Chrome et Safari */
  background-size: cover;
  /* version standardisée */

  .title {
    color: white;
    margin: 0;
    padding: 50px 20px;
  }

  a {
    color: #fff !important;
    text-transform: uppercase;
    text-decoration: none;
    background: #ed3330;
    padding: 20px;
    border-radius: 5px;
    display: inline-block;
    border: none;
    transition: all 0.4s ease 0s;

    &:hover {
      background: #fff;
      color: #ed3330 !important;
      letter-spacing: 1px;
      -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
      -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
      box-shadow: 5px 40px -10px rgba(0, 0, 0, 0.57);
      transition: all 0.4s ease 0s;
    }
  }
}
// SIDEBAR 
.menu-content.h-100 {
  background-color: #f1f2f6;
}

li:hover {
  cursor: pointer;
}

a:hover {
  cursor: pointer;
}

.swal2-confirm {
  width: 103px
}

.btn {
  margin: 5px;
}

.add-button-class {
  margin-bottom: 9px;
  margin-left: 402px;
  margin-top: -33px;
}

.button-items {
  .line {
    font-size: 30px;
    padding: 0;
  }

  button {
    border: none;
  }

  i {
    font-size: 2rem !important;
    font-weight: lighter;
  }

  .btn:disabled {
    border-color: grey;
    color: grey;
  }

}

.page-title {
  font-weight: normal;
  text-transform: uppercase;
  font-size: 20px;
}

.page-title-box {
  padding: 5px 0 !important;
}

.wrning-tag {
  width: 100%;
  margin-top: 0.25rem;
  font-size: .875em;
  color: #ef4d56;
}
//  PREVIEW PANEL 
.preview-data {
  .preview-div {
    margin-left: 11px;
  }

  .table {
    padding: 0.4rem .4em;
  }

  .info-section {
    padding-left: 10px;
  }

  .title {
    font-weight: bold !important;
    color: grey !important;
    font-size: 8pt !important;
  }

  div {
    font-size: 8pt;
    font-weight: 300;
  }

  .card {
    margin-bottom: 5px;
  }

  .card-header {
    padding-bottom: 0;
    padding-top: 0;
  }

  h4 {
    font-weight: lighter !important;
    color: grey !important;
    font-size: 8pt !important;
  }

  span.bold {
    font-size: 8pt;
    font-weight: lighter;
    text-transform: uppercase;
  }

  //Preview Panel Css
  .preview-title {
    //margin-left: 5px;
    margin-bottom: -1px;
    margin-top: 2px;
  }

  .preview-title-examinations {
    //margin-left: 16px;
    margin-bottom: -2px;
  }

  tr.ng-star-inserted {
    font-weight: 500 !important;
    font-size: 8pt;
  }

}

.search-btn {
  margin-top: -18%;
}

.search-btn-appointment {
  padding: 13%;
  margin-top: -18%;
}

.scroll {
  height: calc(100vh - 80px);
  overflow-y: auto;
}

// MAT DIALOGS
.autocomplete-container {
  width: 70% !important;
}
.cdk-overlay-container {
  left: 0%;
  transform: translateX(0%);
  width: 100%;
  z-index: 1001 !important;
}

.mat-option {
  height: 25px !important;
  line-height: 25px;
  font-size: 13px;
}

.mat-option {
  height: 25px !important;
  line-height: 25px;
  font-size: 13px;
}

.mat-dialog-container {
  width: 90% !important;
  margin: auto;
  background-color:  #ffffff !important; 
  height: auto !important;
  padding: 24px !important;
}
.page-wrapper .page-content-tab {
  width: 100% !important;
}

.navbar-custom {
  margin-left: 0 !important;
}
.search-btn {
  margin-top: -7%;
}

.control-button {
  .btn {
    border: none;

    i {
      font-size: 1.5rem;
    }

    .main {
      color: whitesmoke;
    }
  }
}

.cal-events.ng-star-inserted {
  display: none !important;
}


.control-button {
  .btn {
    border: none;

    i {
      font-size: 1.5rem;
    }

    .main {
      color: whitesmoke;
    }
  }
}

.cal-events.ng-star-inserted {
  display: none !important;
}

.patient-name {
  text-transform: capitalize;
}
